<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Bejelentkezés</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-card-text>
                <v-form id="login">
                  <v-row no-gutters v-if="message">
                    <v-col>
                      <v-alert type="error">
                        {{ message }}
                      </v-alert>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <v-text-field label="E-mail" name="login" prepend-icon="mdi-account" type="text"
                        v-model="loginInfo.email" v-on:keydown.enter="
  $event.stopPropagation();
login();
                        " />
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col>
                      <v-text-field id="password" label="Jelszó" name="password" prepend-icon="mdi-lock" type="password"
                        v-model="loginInfo.password" v-on:keydown.enter="
  $event.stopPropagation();
login();
                        " />
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col align="right">
                      <a @click="forgotPassword">Elfelejtettem a jelszavam</a>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click.stop.prevent="login()">Bejlentkezés</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import store from "../../store";
import router from "../../router";
import { API } from "../../api";
export default {
  name: "Login",
  data: () => ({
    loginInfo: {
      email: null,
      password: null,
      remember_me: false,
    },
    message: null,
  }),
  mounted: function () {
    this.message = this.$route.query.message;
  },
  methods: {
    login: function () {
      store
        .dispatch(`auth/login`, this.loginInfo)
        .then(() => {
          this.$store.dispatch("app/loadMenus");
          if (router.currentRoute.query.prevroute) {
            router.push(router.currentRoute.query.prevroute);
          } else {
            router.push({ name: "Dashboard" });
          }
        })
        .catch((err) => {
          this.message = err.response.data.response;
        });
    },
    forgotPassword: function () {
      if (!this.loginInfo.email || this.loginInfo.email.length === 0) {
        alert("Nem adtál meg E-mail címet!");
      } else {
        API.instance
          .post("/auth/reset", { email: this.loginInfo.email })
          .then(() => {
            alert(
              "Ellenőrizd az E-mail címed az instrukciókkal kapcsolatban! 5-10 percet igénybe vehet az E-mail megérkezése"
            );
          });
      }
    },
  },
};
</script>
